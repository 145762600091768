<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <!-- <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link>
                </p>
                </v-col>
            </v-row> -->

            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-alert dense type="warning" v-if="!user.loginshield_id && !user.loginfront_id">
                        Your profile is incomplete. <router-link :to="{ name: 'profile-setup' }" class="white--text">Set up your profile.</router-link>
                    </v-alert>
                    <v-card elevation="2" class="my-6">
                        <v-app-bar color="indigo" dark flat dense>
                            <v-app-bar-title>Dashboard</v-app-bar-title>
                            <!-- <v-spacer/>
                            <v-btn icon @click="openCreateOrganizationDialog">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn> -->
                        </v-app-bar>
                        <v-card-text>
                            <p class="text-overline mb-0 mt-5">Profile</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'profile' }">My profile</router-link>
                            </p>
                            <p class="text-overline mb-0 mt-10">Accounts</p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'user-account-list' }">Manage accounts</router-link>
                            </p>
                            <template v-if="isPermitSystemAdmin">
                            <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="indigo--text"/></p>
                            <p class="mb-0 pb-0">
                                <router-link :to="{ name: 'service-admin' }">Manage LoginFront</router-link>
                            </p>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { isValidName, isValidEmail, isValidPhone } from '@/sdk/input';

export default {
    data: () => ({
        accountList: [],
        // nameRules: [
        //     (v) => (typeof v === 'string' && v.length >= 1) || 'Must not be empty',
        // ],
        nameRules: [
            (v) => isValidName(v) || 'Enter your name',
        ],
        emailRules: [
            (v) => !v || isValidEmail(v) || 'Enter your email address',
        ],
        phoneRules: [
            (v) => !v || isValidPhone(v) || 'Enter your phone number',
        ],
        snackbarIdentityNameUpdated: false,
        snackbarIdentityEmailUpdated: false,
        dialogEditDisplayName: false,
        editableDisplayName: null,
        editableEmail: null,
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        ...mapGetters({
            isPermitSystemAdmin: 'isPermitSystemAdmin',
        }),
        displayName() {
            if (this.user.info && this.user.info.displayName) {
                return this.user.info.displayName;
            }
            return '(unknown)';
        },
        // name: {
        //     get() {
        //         if (this.user.name) {
        //             return this.user.name;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         this.$store.dispatch('editUser', { name: value });
        //     },
        // },
        // email: {
        //     get() {
        //         if (this.user.email) {
        //             return this.user.email;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         // TODO: need email verification first ... let server set it after user clicks link ... server should reject editUser { email } , it should make available a workflow for changing email that a client can initiate
        //         this.$store.dispatch('editUser', { email: value });
        //     },
        // },
        isEditDisplayNameFormComplete() {
            return this.user
            && this.editableDisplayName
            && this.editableDisplayName !== this.user.info.displayName;
        },
        isIdentityNameComplete() {
            return this.user
            && this.editableDisplayName
            && this.editableDisplayName !== this.user.name;
        },
        isIdentityEmailComplete() {
            return this.user
            && this.editableEmail
            && this.editableEmail !== this.user.email;
        },
    },
    watch: {
        dialogEditDisplayName(display) {
            if (display) {
                this.editableDisplayName = this.user.info.displayName;
            }
        },
        email(val) {
            this.editableEmail = val;
        },
        dialogDeleteAccount(val) {
            if (!val) {
                this.closeDialogDeleteAccount();
            }
        },
    },
    methods: {
        async editDisplayName() {
            // try {
            //     this.$store.commit('loading', { editDisplayName: true });
            const isEdited = await this.$store.dispatch('editCurrentUser', { displayName: this.editableDisplayName });
            // this.name = this.editableDisplayName;
            // this.snackbarIdentityNameUpdated = true;
            if (isEdited) {
                this.dialogEditDisplayName = false;
                this.$bus.$emit('snackbar', { type: 'success', message: 'Updated name' });
            } else {
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
            }
            // } catch (err) {
            //     console.error('editDisplayName failed', err);
            //     this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to update name' });
            // } finally {
            //     this.$store.commit('loading', { editDisplayName: false });
            // }
        },
        // editEmail() {
        //     this.email = this.editableEmail;
        //     this.snackbarIdentityEmailUpdated = true;
        // },
        async deleteAccount() {
            this.$store.commit('loading', { deleteAccount: true });
            const response = await this.$client.user(this.$route.params.realmId).delete();
            if (response.isDeleted) {
                await this.$store.dispatch('logout');
                this.$router.push('/');
                console.log('account.vue: deleteAccount success: %o', response);
            } else {
                console.log('account.vue: deleteAccount failed: %o', response);
                this.snackbarAccountDeleteFailed = true;
            }
            this.closeDialogDeleteAccount();
            this.$store.commit('loading', { deleteAccount: false });
        },
        closeDialogDeleteAccount() {
            this.dialogDeleteAccount = false;
        },
        async init() {
            // check user configured authentication
            if (!this.user?.loginfront_id) {
                const query = { ...this.$route.query, step: 'setup_loginfront', t: Date.now() };
                this.$router.push({ name: 'profile-setup', query });
                return;
            }

            // check user has at least one account
            await this.loadAccountList();
            if (!Array.isArray(this.accountList) || this.accountList.length === 0) {
                const query = { ...this.$route.query, step: 'create_account', t: Date.now() };
                this.$router.replace({ name: 'profile-setup', query });
                // return;
            }
        },
        // TODO: move this to store.js and call it with dispatch; so we don't load it in multiple activities redundantly, it can be reused.
        async loadAccountList() {
            try {
                this.$store.commit('loading', { loadAccountList: true });
                const result = await this.$client.user(this.user.id).account.search();
                this.accountList = result.list;
            } catch (err) {
                console.error('failed to load account list', err);
                if (err.response?.status) {
                    console.error(`response status: ${err.response.status}`);
                    // TODO: 300 error codes? server shouldn't be redirecting us...
                    if (err.response.status === 403) {
                        this.resetErrors();
                        this.interactionId = null; // or else user will immediately get same forbidden error again; to start over we need to clear the interaction id
                        this.forbiddenError = true;
                        this.forbiddenErrorTimeout = setTimeout(() => { this.forbiddenError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 400 && err.response.status < 500) {
                        this.requestError = true;
                        this.requestErrorTimeout = setTimeout(() => { this.requestError = false; }, 15000); // clear message in 15 seconds
                    } else if (err.response.status >= 500) {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    } else {
                        this.serverError = true;
                        this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                    }
                } else {
                    this.serverError = true;
                    this.serverErrorTimeout = setTimeout(() => { this.serverError = false; }, 15000); // clear message in 15 seconds
                }
            } finally {
                this.$store.commit('loading', { loadAccountList: false });
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
